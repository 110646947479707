<template>
  <div id="asuo-layout">
    <!-- header -->
    <header-standard
      v-if="$page?.baseHeader.navItems && $page?.baseHeader.navItems.length > 0"
      :is-sticky="isPrimaryNavbarSticky"
      :display-rfi-cta="displayRfiCta"
      home-title="ASU Online"
      logo-link-url="https://www.asu.edu/"
      logo-link-target="_self"
      :rfi-anchor-id="rfiAnchorId"
      :nav-items="($page.baseHeader.navItems as NavItem[])"
      :route="useRequestURL()"
      main-content-id="content"
      @asuSearchEvent="handleAnalyticsEvent"
      @asuLogoClick="handleAnalyticsEvent"
      @homeClick="handleAnalyticsEvent"
      @homeMobileClick="handleAnalyticsEvent"
      @navLinkClick="handleAnalyticsEvent"
      @navCollapseShown="handleAnalyticsEvent"
      @navCollapseHidden="handleAnalyticsEvent"
      @requestInfoClick="handleAnalyticsEvent"
    >
      <template #cta-block>
        <button-apply-now
          v-if="displayApplyNow"
          class="ms-lg-space-xxxs ms-xxl-space-xs"
          text-size="small"
          :type="applyNowType"
          :link-url="applyNowUrl"
          event-region="navbar"
          event-section="main navbar"
          event-component="header-global"
          :has-size-class="true"
        ></button-apply-now>
      </template>
    </header-standard>

    <main
      id="content"
      role="main"
      region="main content"
      tabindex="-1"
      ref="content"
    >
      <!-- alert-internet-explorer -->
      <!-- 
          PLACEHOLDER SWAP
          <div class="asuo-ie-alert">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <alert-internet-explorer></alert-internet-explorer>
              </div>
            </div>
          </div>
        </div> -->

      <!-- a wrapper for slot is needed -->
      <slot />
      <!-- the content -->

      <!-- navbar-bottom: visible only on mobile -->
      <navbar-bottom
        class="d-lg-none"
        v-if="displayBottomNav"
        :apply-now-type="applyNowType"
        :apply-now-url="applyNowUrl"
        :rfi-anchor-id="rfiAnchorId"
        :display-apply-now="displayApplyNow"
        :display-rfi-cta="displayRfiCta"
        :display-chat-cta="displayStickyCta"
        :display-contact-button="displayContactButton"
      ></navbar-bottom>
    </main>

    <!-- asuo-footer -->
    <!-- <ClientOnly> -->
    <footer-standard
      @secondaryMenuLinkClick="handleAnalyticsEvent"
      @tertiaryMenuLinkClick="handleAnalyticsEvent"
      :display-secondary-menu="displayBottomNav"
      :display-apply-now="displayApplyNow"
      :display-rfi-cta="displayRfiCta"
    >
      <template #primary-section>
        <primary-footer-items />
      </template>
      <template #secondary-menu>
        <nav class="nav grid-flex-menu order-1">
          <NuxtLink
            v-for="(item, index) in secondaryNavItems"
            :key="index"
            :to="item.uri"
            :target="formatLinkTarget(item.target)"
            class="nav-link me-0 py-space-xxs py-lg-0 px-0 px-lg-space-xs text-dark-3 menu-item fw-bold"
            rel="noopener noreferrer"
            @click="handleSecondaryMenuLinkClick(item)"
          >
            {{ item.text }}
          </NuxtLink>
        </nav>
      </template></footer-standard
    >
    <!-- </ClientOnly> -->

    <!-- cookie-banner -->
    <cookie-banner> </cookie-banner>
  </div>
</template>

<script setup lang="ts">
import {
  analyticsComposable,
  type AnalyticsEventObject,
} from "@rds-vue-ui/analytics-gs-composable";
import { HeaderStandard } from "@rds-vue-ui/header-standard";
import type { NavItem } from "@rds-vue-ui/header-standard/HeaderStandard.vue";
import CookieBanner from "~/components/CookieBanner.vue";
import ButtonApplyNow from "~/components/nav/ButtonApplyNow.vue";
import NavbarBottom from "~/components/nav/NavbarBottom.vue";
import PrimaryFooterItems from "~/components/nav/PrimaryFooterItems.vue";
import { BaseHeader } from "~/server/resolvers/resolvers-types";

onMounted(() => {
  const observer = $lozad(".rds-lazy");
  observer.observe();
});

const { $lozad } = useNuxtApp();

interface Props {
  isPrimaryNavbarSticky?: boolean;
  applyNowType?: "modal" | "link";
  applyNowUrl?: string;
  displayStickyCta?: boolean;
  displayBottomNav?: boolean;
  displayApplyNow?: boolean;
  displayRfiCta?: boolean;
  rfiAnchorId?: string;
  isHeaderSticky?: boolean;
  displayContactButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isPrimaryNavbarSticky: true,
  applyNowType: "modal",
  applyNowUrl: "#",
  displayStickyCta: true,
  displayBottomNav: true,
  displayApplyNow: true,
  displayRfiCta: true,
  rfiAnchorId: "asuo-rfi-section",
  isHeaderSticky: true,
  displayContactButton: true,
});

interface DefaultLayoutQueryResponse {
  baseHeader: BaseHeader;
}

const $page = await usePageQuery<DefaultLayoutQueryResponse>(
  `#graphql
    query {
      baseHeader(id: "36c62521-a023-46a6-b15b-b668e0f56758"){
        navItems {
          isActive
          htmlLink {
            text
            uri
            target
          }
          children {
            hasBorderTop
            htmlLink {
              text
              uri
              target
            }
          }
        }
      }
    }`,
  {},
  { key: "header-nav", deep: false }
);

const secondaryNavItems = [
  {
    text: "Maps and Locations",
    uri: "https://www.asu.edu/map/interactive/",
    target: "BLNK",
  },
  {
    text: "Jobs",
    uri: "https://www.asu.edu/asujobs",
    target: "BLNK",
  },
  {
    text: "Directory",
    uri: "https://isearch.asu.edu/asu-people/",
    target: "BLNK",
  },
  {
    text: "Contact ASU",
    uri: "https://www.asu.edu/contactasu/",
    target: "BLNK",
  },
  {
    text: "My ASU",
    uri: "https://my.asu.edu/",
    target: "BLNK",
  },
];

const handleAnalyticsEvent = (evt: AnalyticsEventObject) => {
  analyticsComposable.trackEvent(evt);
};

const formatLinkTarget = (target: string) => {
  if (target == "SELF") {
    return "_self";
  } else {
    return "_blank";
  }
};

const handleSecondaryMenuLinkClick = (item: (typeof secondaryNavItems)[0]) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "footer",
    "secondary footer",
    item.text.toLowerCase(),
    "footer - innovation"
  );
};
</script>

<style lang="scss" scoped>
.asuo-enter-active {
  transition: opacity 0.2s;
}
.asuo-enter {
  opacity: 0;
}
nav.grid-flex-menu {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  justify-items: start;
  flex-wrap: wrap;
  width: 100%;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  :deep(.btn-size) {
    padding: 8px 16px;
    font-size: 12px;
  }
  nav.grid-flex-menu {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  :deep(.btn-size) {
    font-size: 14px;
  }
}
</style>
